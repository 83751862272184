import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenavContainer } from '@angular/material';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EasingLogic } from 'ngx-page-scroll-core';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
        height: 0,
      })),
      transition('open => closed', [
        animate('0.2s 100ms ease-out')
      ]),
      transition('closed => open', [
        animate('0.2s 100ms ease-in')
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'temple-website';

  @ViewChild(MatSidenavContainer, {static: false}) sidenavContainer: MatSidenavContainer;

  isHandSet$: Observable<boolean>;
  showMessageBtn = false;

  // ngx-page-scroll setting
  pageScrollOffset = 60;
  pageScrollDuration = 700;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isHandSet$ = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches)
    );
  }

  ngOnInit(): void {
    // setInterval(() => {
      console.log('page_view');
      gtag('event', 'page_view', { 'page_path': 'https://temple.dongfeng.com.tw/index.html' });
    // }, 1200000);

  }

  ngAfterViewInit(): void {

    this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(
      result => {
        console.log(`Handset: ${result.matches}`);
        if (result.matches === false) {
          this.sidenavContainer.close();
        }
      }
    );

  }

  myEasing: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    // easeInOutExpo easing
    if (t === 0) {
      return b;
    }
    if (t === d) {
      return b + c;
    }
    if ((t /= d / 2) < 1) {
      return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    }

    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
  }
}
